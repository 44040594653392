'use client';
import { css, cx } from '@emotion/css';
import { ToastContainer, ToastOptions } from 'react-toastify';

import { Colors } from '@/styles/colors';
import 'react-toastify/dist/ReactToastify.css';

const contextClass = (options: ToastOptions) => {
  switch (options.type) {
    case 'success':
      return css({ backgroundColor: Colors.indigoMain }); // equivalent to bg-indigo-intense
    case 'error':
      return css({ backgroundColor: Colors.red }); // equivalent to bg-red-700
    case 'info':
      return css({ backgroundColor: Colors.persianBlue }); // equivalent to bg-indigo-intense
    default:
      return css({ backgroundColor: Colors.persianBlue }); // equivalent to bg-indigo-intense
  }
};

const toastClassName = (options: any) =>
  cx(
    css({
      display: 'flex',
      alignItems: 'center',
      minWidth: '429px',
      margin: 'auto',
      minHeight: 0,
      borderRadius: '2px',
      padding: '1rem',
    }),
    contextClass(options)
  );

export const Toaster = () => (
  <ToastContainer
    position="bottom-left"
    toastClassName={toastClassName}
    hideProgressBar
    icon={false}
    closeButton={() => <span />}
    bodyClassName={css({
      fontFamily: 'Inter, sans-serif',
      color: '#FFFFFF', // text-white
      fontWeight: 500, // font-medium
      fontSize: '0.75rem', // text-xs
    })}
    autoClose={3000}
    rtl={false}
    draggable
  />
);

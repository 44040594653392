import { PropsWithChildren } from 'react';

import NextNProgress from 'nextjs-progressbar';

import { Colors } from '@/styles/colors';

const RouterWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div>
      <NextNProgress color={Colors.orange500} />
      {children}
    </div>
  );
};

export { RouterWrapper };

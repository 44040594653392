import { appWithTranslation } from 'next-i18next';
import PlausibleProvider from 'next-plausible';
import { AppProps } from 'next/app';

import { injectGlobalCss } from '@/styles/global';
// import '@/styles/globals.css';
// import '../styles/globals.scss';

import { Toaster } from '@/components/Toaster/Toaster';
import { RouterWrapper } from '@/landing/components/RouterWrapper/RouterWrapper';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';

import '@fontsource/sora/600.css';
import '@fontsource/sora/700.css';

import '@fontsource/space-grotesk/400.css';
import '@fontsource/space-grotesk/500.css';
import '@fontsource/space-grotesk/600.css';
import '@fontsource/space-grotesk/700.css';
//
// core styles shared by all of react-notion-x (required)
import { FacebookPixel } from '@/components/FacebookPixel/FacebookPixel';
import 'react-notion-x/src/styles.css';

injectGlobalCss();

interface AppPropsWithToken extends AppProps {}

function MyApp({ Component, pageProps }: AppPropsWithToken) {
  return (
    <PlausibleProvider
      domain={process.env.NEXT_PUBLIC_APP_ORIGIN_DOMAIN || ''}
    >
      <RouterWrapper>
        <Toaster />
        {/*<ClarityScript />*/}
        <FacebookPixel />
        <Component {...pageProps} />
        {/*<GtmScript gtmId={'GTM-PHMRZNFT'} />*/}
      </RouterWrapper>
    </PlausibleProvider>
  );
}

export default appWithTranslation(MyApp);

export enum Colors {
  cardboardBeige = '#f7f2ec',
  cardboardAccent = '#0d2b5a',
  convertoLight = '#e5e5ff',
  convertoAccent = '#6258d5',
  puzzloneLight = '#e19a9e',
  puzzloneAccent = '#cb444a',
  teal50 = '#F0FDFA',
  teal500 = '#14B8A6',
  purple50 = '#FAF5FF',
  purple500 = '#A855F7',
  purple600 = '#7B61FF',
  red50 = '#FEF2F2',
  red100 = '#FEE2E2',
  red500 = '#EF4444',
  red600 = '#DC2626',
  red700 = '#B91C1C',
  blue50 = '#EFF6FF',
  blue500 = '#3B82F6',
  orange50 = '#FFF7ED',
  orange100 = '#FFEDD5',
  orange200 = '#FED7AA',
  orange300 = '#FDBA74',
  orange400 = '#FB923C',
  orange500 = '#F97316',
  orange600 = '#EA580C',
  green50 = '#F0FDF4',
  green100 = '#DCFCE7',
  green500 = '#22C55E',
  green600 = '#16A34A',
  green800 = '#166534',
  cornflowerBlue = '#6366F1',
  catskillWhite = '#f1f5f9',
  orangeWhite = '#FEFCE8',
  white = '#fff',
  indigoIntense = '#1825C4',
  indigo50 = '#EEF2FF',
  indigo100 = '#E0E7FF',
  indigo300 = '#4338CA',
  indigo600 = '#4F46E5',
  indigo800 = '#3730A3',
  zumthor = '#eff6ff',
  persianBlue = '#1d4ed8',
  cloudBurst = '#213752',
  royalBlue = '#3544DB',
  governorBay = '#4338CA',
  linkWater = '#ECEBFA',
  tuna = '#3f3f46',
  dawnPink = '#F7F2EC',
  graySpecial = '#222225',
  gray50 = '#fafafa',
  gray100 = '#f4f4f5',
  gray200 = '#E4E4E7',
  gray300 = '#D4D4D8',
  gray400 = '#A1A1AA',
  gray500 = '#71717a',
  gray600 = '#52525B',
  gray700 = '#3F3F46',
  gray800 = '#27272A',
  gray900 = '#18181B',
  black = '#000000',
  persianGreen = '#00B0A6',
  bilbao = '#128210',
  mantis = '#67CE67',
  tundora = '#404040',
  tapa = '#78716C',
  persianPink = '#F881DE',
  red = '#e46043',
  tulipTree = '#E99C40',
  corn = '#EAB308',
  candleLight = '#FACC15',
  darkred = '#892d19',
  lightGreen = '#45F741',
  yellow = '#F7F041',
  lightBlue = '#62FBF2',
  blueGrayMain = '#64748B',
  blueGrayAlt = '#F1F5F9',
  amberMain = '#F59E0B',
  amberAlt = '#FEF3C7',
  limeMain = '#84CC16',
  limeAlt = '#ECFCCB',
  light6 = '#E7E5E4',
  emeraldMain = '#10B981',
  emeraldAlt = '#D1FAE5',
  cyanMain = '#06B6D4',
  cyanAlt = '#CFFAFE',
  lightBlueMain = '#0EA5E9',
  lightBlueAlt = '#E0F2FE',
  violetMain = '#8B5CF6',
  violetAlt = '##EDE9FE',
  fuchsiaMain = '#D946EF',
  fuchsiaAlt = '#FAE8FF',
  roseMain = '#F43F5E',
  roseAlt = '#FFE4E6',
  pinkMain = '#EC4899',
  pinkAlt = '#FED7E2',
  indigoMain = '#6366F1',
  indigoAlt = '#E0E7FF',
  yellowMain = '#EAB308',
  yellowAlt = '#FEF9C3',
}

export const colors = (color: keyof typeof Colors) => {
  return Colors[color];
};

export const hexToRgb = (hex: string): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  // @ts-ignore
  const [_, r, g, b] =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!r || !g || !b) {
    return '';
  }

  return `${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(b, 16)}`;
};
